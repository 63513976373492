<template lang="html">
  <div class="select-slider">
    <div class="select-slider__title" v-if="title">{{ title }}</div>
    <div class="select-slider__container">
      <div v-if="!hideArrows" class="select-slider__arrow" @click="prevOption">
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M24 12L16 20L24 28" stroke="#c99d66" stroke-width="2" />
          <circle cx="20" cy="20" r="19" stroke="#c99d66" stroke-width="2" />
        </svg>
      </div>
      <div class="select-slider__selected">
        <span v-if="selectedOption">
          <span v-html="selectedOption[label]"></span>
          <svg
            v-if="infoButton"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @click="$emit('openInfo')"
          >
            <rect width="24" height="24" rx="12" fill="#c99d66" />
            <rect
              x="10.5"
              y="5.46729"
              width="2.84211"
              height="2.84211"
              fill="white"
            />
            <rect
              x="10.5"
              y="10.2039"
              width="2.84211"
              height="7.57895"
              fill="white"
            />
          </svg>
        </span>
        <span class="select-slider__placeholder" v-else>{{ placeholder }}</span>
      </div>
      <div v-if="!hideArrows" class="select-slider__arrow" @click="nextOption">
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M16 28L24 20L16 12" stroke="#c99d66" stroke-width="2" />
          <circle
            cx="20"
            cy="20"
            r="19"
            transform="rotate(-180 20 20)"
            stroke="#c99d66"
            stroke-width="2"
          />
        </svg>
      </div>
    </div>
    <div v-if="counter" class="select-slider__counter">
      <b>{{ selectedOptionNumber || 0 }}</b> von {{ options.length }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectSlider',
  props: {
    options: {
      type: Array,
      required: false,
    },
    defaultOption: {
      type: Number,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
      default: 'label',
    },
    title: {
      type: String,
      required: false,
    },
    counter: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideArrows: {
      type: Boolean,
      required: false,
      default: false,
    },
    infoButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedOptionNumber: this.defaultOption,
    };
  },
  watch: {
    defaultOption(val) {
      this.selectedOptionNumber = val;
    },
  },
  computed: {
    selectedOption() {
      return this.selectedOptionNumber
        ? this.options[this.selectedOptionNumber - 1]
        : null;
    },
  },
  methods: {
    prevOption() {
      this.selectedOptionNumber -= 1;

      if (!this.selectedOptionNumber || this.selectedOptionNumber <= 0) {
        this.selectedOptionNumber = this.options.length;
      }

      this.emitEvent();
    },
    nextOption() {
      if (this.selectedOptionNumber === this.options.length) {
        this.selectedOptionNumber = 0;
      }

      this.selectedOptionNumber += 1;
      this.emitEvent();
    },
    emitEvent() {
      this.$emit('changeOption', this.selectedOption);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
