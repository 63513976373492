<template lang="html">
  <div class="back" :style="cssVars">
    <span class="back__container" @click="backEvent">
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 33C26.2843 33 33 26.2843 33 18C33 9.71573 26.2843 3 18 3C9.71573 3 3 9.71573 3 18C3 26.2843 9.71573 33 18 33Z"
          :stroke="color"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18 12L12 18L18 24"
          :stroke="color"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M24 18H12"
          :stroke="color"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span>{{ label }}</span>
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Back',
  props: {
    color: {
      type: String,
      required: true,
      default: '#000000',
    },
    label: {
      type: String,
      required: true,
      default: 'Back',
    },
    customBack: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    cssVars() {
      return {
        '--color': this.color,
      };
    },
  },
  methods: {
    backEvent() {
      if (this.customBack) {
        this.$emit('back');
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
