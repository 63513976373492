const dungeonMap = {
  props: {
    walls: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      panzoomInstance: null,
      isSelectedonMap: false,
      options: {
        minZoom: 1,
        maxZoom: 2,
        bounds: true,
        boundsPadding: 1,
        initialZoom: 1,
      },
      fragments: {
        'gewoelbe-a': 'M168 165V157H158V165L148 175H140V185H148L168 165Z',
        'gewoelbe-c': 'M68 165V157H78V165L88 175H96V185H88L68 165Z',
        'gewoelbe-e': 'M68 104V112H78V104L88 94H96V84H88L68 104Z',
        'gewoelbe-g': 'M168 104V112H158V104L148 94H140V84H148L168 104Z',
        nordtonne:
          'M118 131L137 111V42H142V37H137V33H99V37H94V42H99V111L118 131Z',
        osttonne: 'M122 135L140 154H203V159H209V111H203V116H140L122 135Z',
        'pfeiler-a': 'M151 157V165L148 168H140V173H148L156 165V157H151Z',
        'pfeiler-c': 'M85 157V165L88 168H96V173H88L80 165V157H85Z',
        'pfeiler-e': 'M85 112V104L88 101H96V96H88L80 104V112H85Z',
        'pfeiler-g': 'M151 112V104L148 101H140V96H148L156 104V112H151Z',
        suedtonne:
          'M118 139L99 159V226H94V231H99V239H137V231H142V226H137V159L118 139Z',
        'wand-a': 'M175 165V157H170V165L148 187H140V192H148L175 165Z',
        'wand-c': 'M61 165V157H66V165L88 187H96V192H88L61 165Z',
        'wand-d': 'M12 116H7V154H12V116Z',
        'wand-e': 'M61 104V112H66V104L88 82H96V77H88L61 104Z',
        'wand-g': 'M175 104V112H170V104L148 82H140V77H148L175 104Z',
        westtonne:
          'M114 135L96 116H24V111H19V116H16V154H19V159H24V154H96L114 135Z',
      },
      areas: [
        {
          label: 'Übersicht',
          slug: 'overview',
          coordinates: { x: 0, y: 0 },
        },
        {
          label: 'West',
          slug: 'west',
          coordinates: { x: 0, y: 20 },
          // walls: [33, 26, 24, 23, 22],
        },
        {
          label: 'Nord',
          slug: 'nord',
          coordinates: { x: 90, y: 0 },
          // walls: [33, 26, 24, 23, 22],
        },
        {
          label: 'Ost',
          slug: 'east',
          coordinates: { x: 200, y: 130 },
        },
        {
          label: 'Süd',
          slug: 'south',
          coordinates: { x: 40, y: 150 },
        },
      ],
      selectedAreaOption: {},
      selectedWallOption: {},
      currentAreaIndex: 1,
      currentWallIndex: null,
      showModal: false,
      clickedAreaSelect: false,
    };
  },
  watch: {
    selectedWallSlug(val) {
      this.$nextTick(() => {
        this.isSelectedonMap = false;
      });
      if (this.isSelectedonMap) return;
      const svg = document.getElementById('DungeonMap');
      const path = document.getElementById(val);
      const centerContainer = this.getCenterContainer();

      if (!path || !svg) return;

      const pathData = path.getBoundingClientRect();
      const svgData = svg.getBoundingClientRect();

      const pathX = pathData.left - svgData.left + pathData.width / 2;
      const pathY = pathData.top - svgData.top + pathData.height / 2;

      this.panzoomInstance.smoothZoom(centerContainer.x, centerContainer.y, 2);
      this.panzoomInstance.smoothMoveTo(
        -pathX + centerContainer.x,
        -pathY + centerContainer.y
      );
    },
  },
  computed: {
    selectedWallSlug() {
      return this.$store.state.stones.selectedWallSlug;
    },
    isMobile() {
      return this.$store.state.global.isMobile;
    },
  },
  methods: {
    getCenterContainer() {
      const panContainer = document.querySelector('.vue-pan-zoom-scene');
      const panContainerData = panContainer.getBoundingClientRect();
      const x = panContainerData.width / 2;
      const y = panContainerData.height / 2;

      return { x, y };
    },
    onInit(panzoomInstance) {
      this.panzoomInstance = panzoomInstance;
    },
    selectOnMap(wall) {
      this.isSelectedonMap = true;
      this.$store.commit('stones/setSelectedArea', wall);
      this.$emit('selectOnMap', wall);

      this.currentWallIndex =
        this.walls.findIndex(({ slug }) => wall.slug === slug) + 1;
      this.selectedWallOption = wall;
    },
    changeArea(e) {
      this.clickedAreaSelect = true;
      this.selectedAreaOption = e.slug !== 'overview' ? e : {};
      const centerContainer = this.getCenterContainer();

      if (e.slug === 'overview') {
        this.panzoomInstance.smoothZoom(0, 0, 0);
      } else if (e.slug === 'west') {
        this.moveToArea(0, centerContainer.y, 2);
      } else if (e.slug === 'nord') {
        this.moveToArea(centerContainer.x - 60, 0, 2);
      } else if (e.slug === 'east') {
        this.moveToArea(centerContainer.x * 2 - 40, centerContainer.y, 2);
      } else if (e.slug === 'south') {
        this.moveToArea(centerContainer.x - 40, centerContainer.y * 2, 2);
      }
    },
    moveToArea(x, y, zoom) {
      if (this.panzoomInstance.getTransform().scale !== 1) {
        this.panzoomInstance.smoothMoveTo(-x, -y);
      } else {
        this.panzoomInstance.smoothZoom(x, y, zoom);
      }
    },
    changeWall(e) {
      this.selectedWallOption = e;
      this.$store.commit('stones/setSelectedArea', e);
    },
    endMoving() {
      this.clickedAreaSelect = false;
    },
    transformEvent(e) {
      if (this.clickedAreaSelect) {
        return;
      }
      const moveData = e.getTransform();
      const x = Math.abs(moveData.x);
      const y = Math.abs(moveData.y);

      const currentArea = this.areas.filter(
        ({ coordinates }) => coordinates.x <= x && coordinates.y <= y
      );

      const lastElement = currentArea.pop();
      const indexLastElement = this.areas.findIndex(
        ({ slug }) => slug === lastElement.slug
      );

      this.currentAreaIndex = indexLastElement + 1;
      this.selectedAreaOption = this.areas[indexLastElement];
    },
    openInfo() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    touchEventSelectOnMap(wall) {
      if (this.isMobile) this.selectOnMap(wall);
    },
    clickEventSelectOnMap(wall) {
      // console.log(wall);
      if (!this.isMobile) this.selectOnMap(wall);
    },
  },
};
export default dungeonMap;
