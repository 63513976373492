<template lang="html">
  <div class="steps">
    <template v-for="step in steps" :key="step.id">
      <div
        class="step"
        :class="{
          'step--passed': step.passed,
          'step--current': currentStep === step.id,
        }"
      >
        <span class="step-point"><span></span></span>
        <span class="step-label">{{ step.name }}</span>
      </div>

      <div
        class="step-line"
        :class="{ 'step-line--passed': step.passed }"
      ></div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Steps',
  props: {
    steps: {
      type: Array,
      required: true,
    },
    currentStep: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
