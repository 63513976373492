<template lang="html">
  <transition name="fade">
    <div class="modal-window" v-if="show">
      <div class="modal-window__container" v-click-outside="onClickOutside">
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
import vClickOutside from 'click-outside-vue3';
export default {
  name: 'ModalWindow',
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onClickOutside() {
      this.closeModal();
    },
    closeModal() {
      this.$emit('closeModal');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
