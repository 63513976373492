<template lang="html">
  <div class="stones-form__step-content">
    <FormPaymentMethod
      v-if="stonesState.form.value"
      :donateValue="Number(stonesState.selectedStone.price)"
      @chooseMethod="nextStep"
      @comeBack="prevStep"
    />
  </div>
</template>

<script>
import FormPaymentMethod from '@/components/Form/FormPaymentMethod.vue';

export default {
  name: 'StonesFormStep2',
  components: {
    FormPaymentMethod,
  },
  computed: {
    stonesState() {
      return this.$store.state.stones;
    },
  },
  methods: {
    nextStep() {
      const params = {
        stone_id: this.stonesState.selectedStone.ID,
        paid_price: this.stonesState.selectedStone.price,
        candle: {
          amount: this.stonesState.selectedStone.price,
        },
        postType: 'stones',
      };
      this.$store.dispatch('donationPayment/pay', params);

      // this.$store.commit('stones/setFormCurrentStep', 3);
    },
    prevStep() {
      this.$store.commit('stones/setFormCurrentStep', 1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
