<template lang="html">
  <transition name="sidebar-fade">
    <div class="sidebar" v-show="isShow">
      <div
        class="sidebar__background"
        v-if="isShow"
        @click="closeSidebar"
      ></div>
      <transition name="sidebar-slide">
        <div class="sidebar__container" v-if="isShow">
          <svg
            v-if="!isMobile"
            class="sidebar__close"
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @click="closeSidebar"
          >
            <path d="M1 1L25 25" stroke="#0062AE" stroke-width="2" />
            <path d="M25 1L1 25" stroke="#0062AE" stroke-width="2" />
          </svg>
          <slot />
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Sidebar.vue',
  props: {
    isShow: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    isMobile() {
      return this.$store.state.global.isMobile;
    },
  },
  methods: {
    closeSidebar() {
      this.$emit('closeSidebar');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
