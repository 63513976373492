<template lang="html">
  <div class="steps steps-area" v-if="initialData">
    <div class="steps__container">
      <template v-if="!stonesState.form.formStepsIsShow || !isMobile">
        <div class="steps__header" v-if="isMobile">
          <Back label="" color="#C99D66" :customBack="true" @click="prevStep" />
          <Steps
            :steps="stonesState.steps"
            :currentStep="stonesState.currentStep"
          />
        </div>
        <Areas
          v-if="walls.length && stonesState.currentStep === 1"
          :walls="walls"
        />
        <!--        <Walls v-else-if="stonesState.currentStep === 2" />-->
        <!--        <SelectStone v-else-if="stonesState.currentStep === 3" />-->
        <SelectStone v-else-if="stonesState.currentStep === 2" />
      </template>

      <Sidebar
        :isShow="stonesState.form.formStepsIsShow"
        @closeSidebar="closeSidebar"
      >
        <div class="stones-form">
          <div class="stones-form__columns">
            <div class="stones-form__column">
              <div
                class="stones-form-info"
                v-if="!isMobile && stonesState.form.currentStep !== 3"
              >
                <div class="stones-form-info__heading">
                  <Heading :h-type="1" text="Steinauswahl" />
                </div>
                <div class="stones-form-info__description">
                  Mit dieser Steinpatenschaft unterstützen Sie uns und werden
                  Teil der Geschichte unserer Kirche.
                </div>

                <div class="stones-form-info__columns">
                  <div class="stones-form-info__column">
                    <div class="stones-form-info__label">Gemäuer</div>
                    <div class="stones-form-info__value">
                      {{
                        $store.getters['stones/getWall'](
                          stonesState.selectedArea
                        ).name
                      }}
                    </div>
                  </div>
                </div>
                <div class="stones-form-info__columns">
                  <div class="stones-form-info__column">
                    <div class="stones-form-info__label">Reihe</div>
                    <div class="stones-form-info__value">
                      {{ stonesState.selectedStone.row }}
                    </div>
                  </div>
                  <div class="stones-form-info__column">
                    <div class="stones-form-info__label">Ihr Stein</div>
                    <div class="stones-form-info__value">
                      {{ stonesState.selectedStone.stone }}
                    </div>
                  </div>
                </div>

                <!--                <div class="stones-form-info__form-data">-->
                <!--                  <div class="stones-form-info__row">-->
                <!--                    <span class="stones-form-info__label">Von:</span>-->
                <!--                    <span>-->
                <!--                      {{ changedData.candle_from }}-->
                <!--                    </span>-->
                <!--                  </div>-->
                <!--                  <div class="stones-form-info__row">-->
                <!--                    <span class="stones-form-info__label">Für:</span>-->
                <!--                    <span>-->
                <!--                      {{ changedData.candle_for }}-->
                <!--                    </span>-->
                <!--                  </div>-->
                <!--                  <div class="stones-form-info__row">-->
                <!--                    <span class="stones-form-info__label">Nachricht:</span>-->
                <!--                    <div v-html="changedData.message"></div>-->
                <!--                  </div>-->
                <!--                </div>-->
              </div>
            </div>
            <div class="stones-form__column">
              <div class="steps__header steps__header--steps">
                <Steps
                  :steps="stonesState.form.steps"
                  :currentStep="stonesState.form.currentStep"
                />
              </div>
              <!--              <StonesFormStep0-->
              <!--                v-if="stonesState.form.currentStep === 1"-->
              <!--                @change-data="changeData"-->
              <!--              />-->
              <StonesFormStep1 v-if="stonesState.form.currentStep === 1" />
              <StonesFormStep2 v-if="stonesState.form.currentStep === 2" />
              <StonesFormStep3 v-if="stonesState.form.currentStep === 3" />
            </div>
          </div>
        </div>
      </Sidebar>
    </div>
  </div>
</template>

<script>
import Areas from '@/sections/Stones/Areas';
// import Walls from '@/sections/Stones/Walls';
import Steps from '@/components/Steps/Steps';
import Back from '@/components/Back/Back';
import SelectStone from '@/sections/Stones/SelectStone';
// import StonesFormStep0 from '@/sections/Stones/StonesFormStep0';
import StonesFormStep1 from '@/sections/Stones/StonesFormStep1';
import StonesFormStep2 from '@/sections/Stones/StonesFormStep2';
import StonesFormStep3 from '@/sections/Stones/StonesFormStep3';
import Sidebar from '@/components/Sidebar/Sidebar.vue';
import Heading from '@/components/Heading/Heading.vue';

export default {
  name: 'Stones',
  components: {
    Heading,
    // StonesFormStep0,
    StonesFormStep1,
    StonesFormStep2,
    StonesFormStep3,
    SelectStone,
    Back,
    Areas,
    Steps,
    Sidebar,
    // Walls,
  },
  props: {
    initialData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      changedData: {},
    };
  },
  computed: {
    walls() {
      return this.$store.state.stones.walls;
    },
    stonesState() {
      return this.$store.state.stones;
    },
    isMobile() {
      return this.$store.state.global.isMobile;
    },
    donationPaymentStore() {
      return this.$store.state.donationPayment;
    },
  },
  created() {
    this.$store.dispatch('stones/fetchWalls');
  },
  beforeUnmount() {
    this.$store.dispatch('stones/reset');
  },
  updated() {
    const elem = document.querySelector('.app-container__content');
    elem.scrollTop = 0;
  },
  methods: {
    changeData(e) {
      this.changedData = e;
    },
    prevStep() {
      if (this.stonesState.currentStep === 1) {
        this.$router.go(-1);
      } else {
        this.$store.commit(
          'stones/setCurrentStep',
          this.stonesState.currentStep - 1
        );
      }
    },
    closeSidebar() {
      this.$store.commit('stones/setFormStepsIsShow', false);
      this.$store.commit('stones/setFormCurrentStep', 1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
