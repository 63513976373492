<template lang="html">
  <div class="walls" v-if="isMobile">
    <div class="steps__heading">
      <Heading :hType="3" text="Steinauswahl" textAlign="center" />
      <Heading :hType="1" text="Stein" textAlign="center" />
    </div>
    <div class="steps__description">
      Wählen Sie Ihren Stein mit den Dropdown- Optionen und überprüfen Sie seine
      Position auf dem Bild.
    </div>

    <div class="walls__row">
      <div class="walls__column">
        <Select
          v-model="selected.price"
          label="PREIS:"
          :options="stonesPrices"
          :clear="true"
          placeholder="Preis wählen"
          @change="changePrice"
          @clear-event="clearPrice"
        />
      </div>
    </div>

    <div class="walls__row">
      <div class="walls__column-2">
        <Select
          v-model="selected.line"
          label="REIHE:"
          :options="stonesLines"
          placeholder="Reihe wählen"
          @change="changeRow"
        />
      </div>
      <div class="walls__column-2">
        <Select
          v-model="selected.stone"
          label="STEIN:"
          :options="stonesNumbers"
          placeholder="Stein wählen"
          @change="changeStone"
        />
      </div>
    </div>

    <div class="walls__row">
      <div class="walls__column">
        <Polygons
          v-if="filteredStones.length"
          :width="stones.width"
          :height="stones.height"
          :image="stones.img"
          :areas="filteredStones"
          :selected="selected"
          :selectedByFilters="selectedByFilters"
        />
      </div>
    </div>

    <div class="walls__row">
      <div class="walls__column">
        <div class="walls__button">
          <Button
            backgroundColor="#C99D66"
            @click="nextStep"
            :disabled="!this.$store.state.stones.selectedStone"
          >
            <span v-if="globalSettings">
              {{ globalSettings['button_names']['choose_and_pay'] }}
            </span>
          </Button>
        </div>
      </div>
    </div>
  </div>
  <div class="walls walls--desktop" v-else>
    <Back
      v-if="globalSettings"
      color="#0062ae"
      :label="globalSettings['button_names']['back_button']"
    />
    <div class="row gray-background">
      <div class="left">
        <Polygons
          v-if="filteredStones.length"
          :width="stones.width"
          :height="stones.height"
          :image="stones.img"
          :areas="filteredStones"
          :selected="selected"
          :selectedByFilters="selectedByFilters"
        />
      </div>
      <div class="right">
        <Steps
          :steps="stonesState.steps"
          :currentStep="stonesState.currentStep"
        />
        <div class="steps__heading">
          <Heading :hType="3" text="Steinauswahl" textAlign="center" />
          <Heading :hType="1" text="Stein" textAlign="center" />
        </div>
        <div class="steps__description">
          Wählen Sie Ihren Stein mit den Dropdown- Optionen und überprüfen Sie
          seine Position auf dem Bild.
        </div>
        <div class="walls__row">
          <div class="walls__column">
            <Select
              v-model="selected.price"
              label="PREIS"
              :options="stonesPrices"
              :clear="true"
              placeholder="Preis wählen"
              @change="changePrice"
              @clear-event="clearPrice"
            />
          </div>
        </div>
        <div class="walls__row">
          <div class="walls__column">
            <Select
              v-model="selected.line"
              label="REIHE"
              :options="stonesLines"
              placeholder="Reihe wählen"
              @change="changeRow"
            />
          </div>
        </div>
        <div class="walls__row">
          <div class="walls__column">
            <Select
              v-model="selected.stone"
              label="STEIN"
              :options="stonesNumbers"
              placeholder="Stein wählen"
              @change="changeStone"
            />
          </div>
        </div>
        <div class="buttons-wrapper">
          <div class="walls__button">
            <Button
              styleButton="border"
              @click="$store.commit('stones/setCurrentStep', 1)"
            >
              <span>Zurück</span>
            </Button>
            <Button
              backgroundColor="#C99D66"
              @click="nextStep"
              :disabled="!this.$store.state.stones.selectedStone"
            >
              <span v-if="globalSettings">
                {{ globalSettings['button_names']['choose_and_pay'] }}
              </span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button/Button';
import Heading from '@/components/Heading/Heading';
import Select from '@/components/Select/Select';
import Polygons from '@/components/Polygons/Polygons';
import Back from '@/components/Back/Back';
import Steps from '@/components/Steps/Steps';

export default {
  name: 'SelectStone',
  components: { Polygons, Heading, Button, Select, Back, Steps },
  data() {
    return {
      selected: {
        price: null,
        line: null,
        stone: null,
      },
      selectedByFilters: {
        line: null,
        stone: null,
      },
      filteredStones: [],
    };
  },
  watch: {
    '$store.state.stones.selectedStone'(val) {
      if (!val) return;
      this.selected.price = { value: val.price, label: val.price };
      this.selected.line = this.stonesLines.find(
        ({ value }) => value === val.row
      );
      this.selected.stone = { value: val.stone, label: val.stone };
    },
    'stones.areas'(val) {
      this.filteredStones = val;
    },
  },
  computed: {
    stonesState() {
      return this.$store.state.stones;
    },
    isMobile() {
      return this.$store.state.global.isMobile;
    },
    stones() {
      return this.$store.state.stones.stones;
    },
    globalSettings() {
      return this.$store.state.global.settings;
    },
    formatStones() {
      const rows = {};

      this.filteredStones.forEach(({ row }) => {
        rows[row] = {
          row,
          stones: {},
        };
      });

      this.filteredStones.forEach(({ row, stone }) => {
        rows[row]['stones'][stone] = { value: stone, label: stone };
      });

      return rows;
    },
    stonesLines() {
      const rows = [];
      for (const key in this.formatStones) {
        rows.push({
          value: this.formatStones[key].row,
          label: this.formatStones[key].row,
          stones: this.formatStones[key].stones,
        });
      }
      return rows;
    },
    stonesNumbers() {
      let stones = [];
      if (this.selected.line) {
        stones = [];
        const stonesByRow = this.selected.line.stones;
        for (const stonesKey in stonesByRow) {
          stones.push(stonesByRow[stonesKey]);
        }
      } else {
        stones = [];
        const allStones = {};
        for (const key in this.formatStones) {
          for (const keyKey in this.formatStones[key]['stones']) {
            allStones[keyKey] = this.formatStones[key]['stones'][keyKey];
          }
        }
        for (const stonesKey in allStones) {
          stones.push(allStones[stonesKey]);
        }
      }

      return stones;
    },
    stonesPrices() {
      const prices = {};
      this.stones.areas.forEach(({ price }) => {
        if (price) prices[price] = { value: price, label: price };
      });
      const pricesArr = [];
      for (const key in prices) {
        pricesArr.push({
          value: key,
          label: key,
        });
      }
      return pricesArr;
    },
  },
  mounted() {
    this.$store.dispatch(
      'stones/fetchStones',
      this.$store.state.stones.selectedArea
    );
  },
  beforeUnmount() {
    this.$store.commit('stones/setStones', []);
  },
  methods: {
    nextStep() {
      if (!this.$store.state.stones.selectedStone) return;
      this.$store.commit('stones/setFormStepsIsShow', true);
    },
    changePrice({ value }) {
      this.filteredStones = this.stones.areas.filter(
        ({ price }) => price === value
      );
      this.selected.line = null;
      this.selected.stone = null;
      this.selectedByFilters.line = null;
      this.selectedByFilters.stone = null;
    },
    changeRow() {
      this.selected.stone = null;
    },
    changeStone() {
      this.selectedByFilters.line = this.selected.line;
      this.selectedByFilters.stone = this.selected.stone;
    },
    clearPrice() {
      this.selected.price = null;
      this.filteredStones = this.stones.areas;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
.walls__button {
  display: flex;
  gap: 22px;

  :deep() {
    .button {
      white-space: nowrap;
    }
    .button--border {
      background-color: transparent;
    }
  }
}
</style>
