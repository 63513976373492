<template lang="html">
  <div class="poly">
    <div
      class="poly__rows"
      :class="{ 'poly__rows--reverse': isReversRows }"
      :style="`height: ${rowsContainer.height}px; top:${rowsContainer.top}px`"
    >
      <div
        class="poly__row"
        v-for="(row, key) in rows"
        :key="key"
        :style="`flex:${row.records.height}`"
      >
        {{ key }}
      </div>
    </div>
    <div class="poly__zoom-container">
      <panZoom
        :options="options"
        selector=".poly__image-container"
        @transform="transformEvent"
        @init="onInit"
      >
        <div class="poly__image-container">
          <img :src="image" alt="Image" :width="width" :height="height" />
          <svg>
            <polygon
              v-for="area in areas"
              :points="area.coordinates"
              :key="area.ID"
              :class="{ selected: area.ID === selectedStone.ID }"
              :data-row="area['row']"
              :data-id="area.ID"
              :data-unique_id="area.unique_id"
              @touchstart="touchEventSelectStone(area)"
              @click="clickEventSelectStone(area)"
            />
          </svg>
        </div>
      </panZoom>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Polygons',
  props: {
    image: {
      type: String,
      required: false,
    },
    width: {
      type: String,
      required: false,
    },
    height: {
      type: String,
      required: true,
    },
    areas: {
      type: Array,
      required: true,
    },
    selected: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    selectedByFilters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      panzoomInstance: null,
      isReversRows: false,
      options: {
        minZoom: 1,
        maxZoom: 5,
        bounds: true,
        boundsPadding: 1,
        initialZoom: 1,
        // initialX: 0,
        // initialY: 0,
        // transformOrigin: { x: 0.5, y: 0.5 },
        // transformOrigin: null,
      },
      selectedStone: this.selected,
      rows: {},
      rowsContainer: {
        height: 0,
        top: 0,
      },
      zoomContainer: {
        width: 0,
        height: 0,
        top: 0,
      },
    };
  },
  watch: {
    areas() {
      this.$nextTick(() => {
        this.rows = {};
        this.calculateZoomContainer();
        this.setRows();
        this.calculateRowHeight(this.rows);
      });
    },
    selectedByFilters: {
      deep: true,
      handler(item) {
        if (item.line && item.stone) {
          const findStone = this.areas.find(
            ({ row, stone }) =>
              item.line.value === row && item.stone.value === stone
          );
          this.selectedStone = findStone;
          this.$store.commit('stones/setSelectedStone', findStone);

          const coordinates = findStone.coordinates.split(',');
          const xs = [],
            ys = [];
          coordinates.forEach((el, i) => {
            if (this.isOdd(i)) {
              ys.push(Number(el));
            } else {
              xs.push(Number(el));
            }
          });

          const averageX = this.average(xs);
          const averageY = this.average(ys);

          const panContainer = document.querySelector('.vue-pan-zoom-scene');
          let panContainerX = 0,
            panContainerY = 0;
          if (panContainer) {
            const panContainerData = panContainer.getBoundingClientRect();
            panContainerX = panContainerData.width / 2;
            panContainerY = panContainerData.height / 2;
          }
          this.panzoomInstance.zoomTo(0, 0, 0);
          this.panzoomInstance.moveTo(
            -averageX + panContainerX,
            -averageY + panContainerY
          );
        } else {
          this.selectStone({});
        }
      },
    },
  },
  computed: {
    isMobile() {
      return this.$store.state.global.isMobile;
    },
  },
  mounted() {
    this.calculateZoomContainer();
    this.setRows();
    this.calculateRowHeight(this.rows);
  },
  methods: {
    onInit(panzoomInstance) {
      this.panzoomInstance = panzoomInstance;
    },
    isOdd(num) {
      return num % 2;
    },
    average(arr) {
      return arr.reduce((sume, el) => sume + el, 0) / arr.length;
    },
    selectStone(stone) {
      this.selectedStone = stone;
      this.$store.commit('stones/setSelectedStone', stone);
    },
    setRows() {
      // Add rows
      const domElements = document.querySelectorAll('polygon[data-row]');
      if (!domElements) return false;

      domElements.forEach((el) => {
        const rowId = el.getAttribute('data-row');
        this.rows[rowId] = {};
      });
    },
    calculateRowHeight(rows) {
      // Add coordinates for rows from dom stones
      for (const key in rows) {
        const el = document.querySelector(`polygon[data-row="${key}"]`);
        this.rows[key] = {
          records: el.getBoundingClientRect(), //getBoundingClientRect() getBBox()
        };
      }
      this.calculateRowsContainerPosition();
    },
    calculateRowsContainerPosition() {
      const firstKey = Math.min(...Object.keys(this.rows));
      const lastKey = Math.max(...Object.keys(this.rows));
      const firstKeyTop = this.rows[firstKey].records.top;
      const lastKeyTop = this.rows[lastKey].records.top;

      const minKey = firstKeyTop > lastKeyTop ? firstKey : lastKey;
      const maxKey = firstKeyTop > lastKeyTop ? lastKey : firstKey;

      this.isReversRows = firstKeyTop > lastKeyTop;

      this.rowsContainer.top =
        this.rows[maxKey].records.y - this.zoomContainer.top;

      this.rowsContainer.height =
        this.rows[minKey].records.bottom -
        this.zoomContainer.top -
        this.rowsContainer.top;
    },
    calculateZoomContainer() {
      const container = document.querySelector('.poly__zoom-container');
      const { width, height, y } = container.getBoundingClientRect();

      this.zoomContainer.width = width;
      this.zoomContainer.height = height;
      this.zoomContainer.top = y;
    },
    transformEvent() {
      this.calculateZoomContainer();
      this.calculateRowHeight(this.rows);
    },
    touchEventSelectStone(stone) {
      if (this.isMobile) this.selectStone(stone);
    },
    clickEventSelectStone(stone) {
      if (!this.isMobile) this.selectStone(stone);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
