<template lang="html">
  <div class="areas">
    <div class="steps__heading" v-if="isMobile">
      <Heading :hType="3" text="Steinauswahl" textAlign="center" />
      <Heading :hType="1" text="Stein" textAlign="center" />
    </div>
    <DungeonMap :walls="walls" v-if="isMobile" />
    <DungeonMapDesktop :walls="walls" v-else />
  </div>
  <Button
    v-if="isMobile"
    backgroundColor="#C99D66"
    :disabled="!$store.state.stones.selectedArea"
    @click="nextStep"
  >
    <span v-if="globalSettings">
      {{ globalSettings['button_names']['select_area'] }}
    </span>
  </Button>
</template>

<script>
import Button from '@/components/Button/Button';
import Heading from '@/components/Heading/Heading';
import DungeonMap from './DungeonMap';
import DungeonMapDesktop from './DungeonMapDesktop';

export default {
  name: 'Areas',
  components: { DungeonMapDesktop, DungeonMap, Heading, Button },
  props: {
    walls: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedArea: {},
      selectedWall: '',
    };
  },
  computed: {
    globalSettings() {
      return this.$store.state.global.settings;
    },
    isMobile() {
      return this.$store.state.global.isMobile;
    },
  },
  methods: {
    nextStep() {
      if (this.$store.state.stones.selectedArea) {
        this.$store.commit('stones/setCurrentStep', 2);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
