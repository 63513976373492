<template lang="html">
  <div class="stones-form__step-content">
    <FormCheckout @checkoutNextStep="nextStep" />
  </div>
</template>

<script>
import FormCheckout from '@/components/Form/FormCheckout.vue';

export default {
  name: 'StonesFormStep3',
  components: {
    FormCheckout,
  },
  methods: {
    nextStep() {
      // this.$store.commit('stones/setFormCurrentStep', 4);
      this.$store.commit('stones/setFormStepsIsShow', false);
      this.$store.commit('stones/setFormCurrentStep', 1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
